import React from 'react'
import { Link } from 'gatsby'

const NewCs = () => {
  return (
    <div className={'container'}>
      <ul>
        <li><Link to={'/nase-prace/pripadove-studie/webstranky/eshopy/mobilne-telefony'}>/nase-prace/pripadove-studie/webstranky/eshopy/mobilne-telefony</Link></li>
        <li><Link to={'/nase-prace/pripadove-studie/webstranky/firemne/dentique'}>/nase-prace/pripadove-studie/webstranky/firemne/dentique</Link></li>
        <li><Link to={'/nase-prace/pripadove-studie/webstranky/eshopy/kuupto'}>/nase-prace/pripadove-studie/webstranky/eshopy/kuupto</Link></li>
        <li><Link to={'/nase-prace/pripadove-studie/aplikacie/cliqdate/'}>/nase-prace/pripadove-studie/aplikacie/cliqdate/</Link></li>
        <li><Link to={'/nase-prace/pripadove-studie/webstranky/crm/vermont-matrix'}>/nase-prace/pripadove-studie/webstranky/crm/vermont-matrix</Link></li>
        <li><Link to={'/nase-prace/pripadove-studie/webstranky/eshopy/playerland'}>/nase-prace/pripadove-studie/webstranky/eshopy/playerland</Link></li>
      </ul>
    </div>
  )
}

export default NewCs
